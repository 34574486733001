
import MessageModal from "@/components/modals/geral/MessageModal.vue";
import { ref, onMounted } from 'vue';
import { Modal } from "bootstrap";
import distribuidorConfig from "@/core/config/DistribuidorConfig";
import { getoficinaDigitalFinalizadosPdf } from '@/services/OficinaDigital';
import { sendEmailExport } from '@/services/OnePageReportService';
import usePDF from '@/composables/Pdf'
import moment from 'moment';
import { useField, useForm } from 'vee-validate';
import useEmitter from '@/composables/Emmiter';
import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import useAlert from "@/composables/Alert";
import { useStore } from "vuex";

export default {
  	name: "export-modal",
    components: {
        MessageModal,
        QuillEditor
    },
	setup(props){
        const store = useStore();
        const concessionariaInfo:any = ref({});
        const data:any = ref("");

        const { exportBlob } = usePDF();
        const disabledButtonExportar = ref(false);
        const loadingEmail = ref(false);
        const loadingExportFull = ref(false);
        const loadingExportPart = ref(false);
        const showClose = ref(false);
        const showExportFull = ref(true);
        const showExportPart = ref(true);
        const modalExport = ref();
        const modalShareEmail = ref();
        const contentResponse = ref();
        const contentEmail = ref("");
        const initialEmails = ref("");

        const simpleSchema = {
            formEmail(value) {
                if (value && value.trim()) {
                return true;
                }
                return "* Necessário preencher o campo email";
            },
        };
        const toolbarOptions = [
            [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
            ['bold', 'italic', 'underline'],        // toggled buttons
            [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }],
            ['blockquote', 'code-block', 'link'],
            ['image', { 'color': [] }, 'clean'],
        ];

        useForm({
            validationSchema: simpleSchema,
        });

        const { showTimeAlert } = useAlert();

        const { value: formEmail, errorMessage: emailError } = useField<string>('formEmail');

        function reset(){
            showExportPart.value = true;
            showExportFull.value = true;
            contentResponse.value = "";

            clear();
        }

        async function enviarEmail(){
            loadingEmail.value = true;
            const trataEmails = formEmail.value.replaceAll(" ", "");

             const postData = {
                 titulo: geraDataTratada(),
                 codConcessionaria: concessionariaInfo.value.codConcessionaria,
                 conteudo: contentEmail.value,
                 email: trataEmails,
                 pdf: contentResponse.value,
                 nomeArquivo: "OFICINA DIGITAL"
             };
            try{
                const response = await sendEmailExport(postData);
                clear();
                modalShareEmail.value.hide();
                showTimeAlert('O PDF foi compartilhado com sucesso!');
            } catch (e) {
                showTimeAlert('Não foi possível completar a solicitação', "error");
            } finally {
                loadingEmail.value = false;
            }
        }
    	
        const emitter = useEmitter();

        const dataFiltro = ref();
        const consultorFiltro = ref();
        const placaFiltro = ref();
        const auxShowDiarioPDF = ref(false);

        emitter.on("filtrar-od-finalizados", async params  => {
            reset();
            dataFiltro.value = params.data;
            consultorFiltro.value = params.consultorSelected;
            placaFiltro.value = params.placa;
        });

        emitter.on("filtrar-od-finalizados-pdf-show", async (showDiario)  => {
            auxShowDiarioPDF.value = showDiario;
        });

        store.watch(() => store.getters.concessionariaSelectedInfo, () => {
            concessionariaInfo.value = Object.assign({}, store.getters.concessionariaSelectedInfo);
        }, { deep: true });

        function clear(){
            contentEmail.value = "";
            formEmail.value = initialEmails.value;

            const refContent:any = document.querySelector(".ql-editor");
            
            if(refContent){
                while (refContent.firstChild) {
                    refContent.removeChild(refContent.firstChild);
                }
            }
        }

        function geraDataTratada(){
            const dataInicial = moment(data.value[0]);
            const dataFinal = moment(data.value[1]);

            const mesmoAno = dataFinal.year() === dataInicial.year();
            const mesmoMes = dataFinal.month() === dataInicial.month();

            if (mesmoAno && mesmoMes) {
                return `${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`;
            };

            return `${dataInicial.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")} à ${dataFinal.locale("pt-br").format('MMMM - YYYY').replaceAll("-", "de")}`
        }

        async function exportarRelatorio(exportFull = false){
            const codConcessionaria:any = concessionariaInfo.value.codConcessionaria ?? window.localStorage.getItem("filtroConcessionaria");

            disabledButtonExportar.value= true;
            const dataTratada = retornaFiltroData(data.value[0], data.value[1]);
            const distribuidor = distribuidorConfig[0]; 

            let response;
            try {
                if(exportFull){
                    loadingExportFull.value= true;
                    response = await getoficinaDigitalFinalizadosPdf(distribuidor.distribuidor, codConcessionaria, dataFiltro.value, consultorFiltro.value, placaFiltro.value, auxShowDiarioPDF.value);
                    loadingExportFull.value= false;
                    showExportPart.value = false;
                }else{
                    // loadingExportPart.value= true;
                    emitter.emit("geraXLSX");
                    // loadingExportPart.value= false;
                    showExportFull.value = false;
                }
                
                contentResponse.value = response;            
            } catch(e) {
                showTimeAlert('Não foi possível completar a solicitação', "error");
            } finally {
                loadingExportFull.value= false;
                loadingExportPart.value= false;
                disabledButtonExportar.value = false;
            }
        }

        function exportaRelatorio(){
            if(showExportFull.value){
                exportBlob(contentResponse.value, "OrcamentosFinalizados");
            } else {
                emitter.emit("exportXLSX");
            }
        }

        function retornaFiltroData(dataInicial, dataFinal){
            dataInicial = moment(dataInicial).format();
            dataFinal = moment(dataFinal).endOf('month').format();
            dataInicial = dataInicial.substr(0, 10) + " 00:00:00";
            dataFinal = dataFinal.substr(0, 10) + " 23:59:59";

            const filters = `${dataInicial}/${dataFinal}`;
            
            return filters;
        };

        onMounted(() => {
            const refModal = document.getElementById("export-modal");
            if(refModal){
                document.getElementById("layout_content")?.appendChild(refModal);
            }
            modalExport.value = new Modal(refModal);

            const refModalEmail = document.getElementById("modal-share-email");
            if(refModalEmail){
                document.getElementById("layout_content")?.appendChild(refModalEmail);
            }

            modalShareEmail.value = new Modal(refModalEmail);
        });

        return { showExportPart, showExportFull, clear, contentEmail, toolbarOptions, enviarEmail, modalShareEmail, loadingEmail, formEmail, emailError, reset, loadingExportFull, loadingExportPart, disabledButtonExportar, showClose, modalExport, exportaRelatorio, exportarRelatorio, contentResponse };
    },
};
